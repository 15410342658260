import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';

import { useAlert } from 'contexts/alert/useAlert';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import {
  CollectionDto,
  CollectionDtoArchiveInput,
  CollectionDtoMutationInput,
  ConvertToOffPlatformInput,
  ListContainer,
  useCollectionDtoArchiveMutation,
  useCollectionDtoCopyMutation,
  useCollectionDtoUpdateMutation,
  useConvertToOffPlatformMutation
} from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { TOffPlatformInput } from '../components/collection-off-platform-overlay/CollectionOffPlatformOverlay';

export const useCollectionPublish = () => {
  const [notificationText, setNotificationText] = useState('');
  const [waitingForAllesseh, setWaitingForAllesseh] = useState(false);

  const { currentProperty: routeProperty } = useAuth();
  const { alertError, removeAlert, alertSuccess } = useAlert();
  const navigate = useNavigate();
  const currentProperty = useConvertedProperty();
  const { getDTO, hasModelChangedOnce, resetModelChanged } = useDataModelContext<ListContainer>();

  const { mutateAsync: collectionUpdateMutateAsync, isLoading: isUpdating } = useCollectionDtoUpdateMutation();
  const { mutateAsync: collectionCopyMutateAsync, isLoading: isCopying } = useCollectionDtoCopyMutation();
  const { mutateAsync: collectionArchivingMutateAsync, isLoading: isArchiving } = useCollectionDtoArchiveMutation();
  const { mutateAsync: convertToOffPlatformAsync, isLoading: isConvertingToOffPlatform } =
    useConvertToOffPlatformMutation();

  const handleSave = async () => {
    const collectionDTO = getDTO(true);

    removeAlert();

    if (!collectionDTO.metadata.name) {
      alertError('Could not update the collection. Name cannot be blank.');
      return;
    }

    const updateInput: CollectionDtoMutationInput = {
      collectionDTO,
      publicationKey: currentProperty ?? ''
    };

    try {
      const { collectionDTOUpdate } = await collectionUpdateMutateAsync({ collectionDTOUpdateInput: updateInput });

      if (!collectionDTO.metadata.allessehId && collectionDTOUpdate.idCreatedUtc) {
        navigate(`/${routeProperty}/collections/${collectionDTOUpdate.idCreatedUtc}`);
      } else {
        resetModelChanged();
        setNotificationText('Saved');
        alertSuccess('Collection successfully saved');
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        alertError(`Could not update the collection. ${e.message}`);
      } else {
        alertError('Could not update the collection.');
      }
    }
  };

  const handleCopy = async () => {
    const collectionDTO = getDTO(true) as CollectionDto;

    removeAlert();

    const copyInput: CollectionDtoMutationInput = {
      collectionDTO,
      publicationKey: currentProperty ?? ''
    };

    try {
      const resp = await collectionCopyMutateAsync({ collectionDTOCopyInput: copyInput });
      navigate(`/${routeProperty ?? ''}/collections/${resp.collectionDTOCopy.idCreatedUtc}`);
    } catch (e: unknown) {
      if (e instanceof Error) {
        alertError(`Could not copy the collection. ${e.message}`);
      } else {
        alertError('Could not copy the collection.');
      }
    }
  };

  const handleArchive = async () => {
    const collectionDTO = getDTO(true) as CollectionDto;

    removeAlert();

    const archiveInput: CollectionDtoArchiveInput = {
      collectionDTO,
      isArchived: !collectionDTO.metadata.isArchived,
      publicationKey: currentProperty ?? ''
    };

    try {
      await collectionArchivingMutateAsync({ collectionDTOArchiveInput: archiveInput });
      window.location.reload();
    } catch (e: unknown) {
      if (e instanceof Error) {
        alertError(`Could not archive the collection. ${e.message}`);
      } else {
        alertError('Could not archive the collection.');
      }
    }
  };

  const handleOffPlatformConvert = async (offPlatformInput: TOffPlatformInput) => {
    const collectionDTO = getDTO(true) as CollectionDto;

    removeAlert();

    const convertToOffPlatformInput: ConvertToOffPlatformInput = {
      allessehCollectionId: collectionDTO.metadata.allessehId,
      consumer: offPlatformInput.consumer,
      defaultAltSummVariant: offPlatformInput.defaultAltSummVariant,
      hostingPageURL: offPlatformInput.hostingPageURL,
      moduleDisplayName: offPlatformInput.moduleDisplayName,
      publicationKey: currentProperty ?? '',
      maxItems: offPlatformInput.maxItems,
      minItems: offPlatformInput.minItems
    };

    try {
      setWaitingForAllesseh(true);
      const resp = await convertToOffPlatformAsync({
        convertToOffPlatformInput
      });

      /*
        TODO: This is a temp fix for ET-5557
        Adds a 5 sec timer before off-platform redirect.
        Allesseh's processing time may vary, this will
        probably need further adjustment.
      */
      setTimeout(() => {
        setWaitingForAllesseh(false);
        navigate(`/${routeProperty ?? ''}/off-platform/${resp.convertToOffPlatform.idCreatedUtc}`);
      }, 5000);
    } catch (e: unknown) {
      let message = 'Could not convert to off-platform. Please try again.';
      if (e instanceof Error) message = e.message;
      alertError(message);
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (notificationText) {
      timeout = setTimeout(() => {
        setNotificationText('');
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [notificationText]);

  return {
    hasCollectionChanged: hasModelChangedOnce,
    notificationText,
    handleSave,
    isUpdating,
    handleCopy,
    isCopying,
    handleArchive,
    isArchiving,
    handleOffPlatformConvert,
    isConvertingToOffPlatform,
    waitingForAllesseh
  };
};
