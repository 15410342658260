import { QueryRuleFormKeyConfig, QueryRuleFormType } from './types';

export const DEFAULT_CONFIG: Record<string, QueryRuleFormKeyConfig> = {
  // AFPNewsCode: { label: 'AFP News Code', queryRuleFormType: QueryRuleFormType.TEXT },
  Author: { queryRuleFormType: QueryRuleFormType.TEXT },
  // AuthorId: { label: 'Author ID', queryRuleFormType: QueryRuleFormType.TEXT },
  AvailabilityFlags: { queryRuleFormType: QueryRuleFormType.TEXT },
  // BarronsDisplayBrand: { queryRuleFormType: QueryRuleFormType.TEXT },
  // ContentStatus: { queryRuleFormType: QueryRuleFormType.TEXT },
  // ContentType: { queryRuleFormType: QueryRuleFormType.TEXT },
  // ColumnName: { queryRuleFormType: QueryRuleFormType.TEXT },
  // DJNCode: { label: 'DJN Code', queryRuleFormType: QueryRuleFormType.TEXT },
  // DocType: { queryRuleFormType: QueryRuleFormType.TEXT },
  // DoNotArchive: { queryRuleFormType: QueryRuleFormType.TEXT },
  // DraftStatus: { queryRuleFormType: QueryRuleFormType.TEXT },
  // Editor: { queryRuleFormType: QueryRuleFormType.TEXT },
  // FCode: { queryRuleFormType: QueryRuleFormType.TEXT },
  // IndustryCode: { queryRuleFormType: QueryRuleFormType.TEXT },
  // IndustryCodeName: { queryRuleFormType: QueryRuleFormType.TEXT },
  // IsEvergreen: { inputComponent: BoolForm },
  Keyword: { queryRuleFormType: QueryRuleFormType.TEXT },
  // LanguageCode: { queryRuleFormType: QueryRuleFormType.TEXT },
  Page: { queryRuleFormType: QueryRuleFormType.TEXT },
  // PersonCodeName: { queryRuleFormType: QueryRuleFormType.TEXT },
  // PrintHeadline: { queryRuleFormType: QueryRuleFormType.TEXT },
  // Product: { queryRuleFormType: QueryRuleFormType.TEXT },
  // Publisher: { queryRuleFormType: QueryRuleFormType.TEXT },
  // RegionCode: { queryRuleFormType: QueryRuleFormType.TEXT },
  // RelayCode: { queryRuleFormType: QueryRuleFormType.TEXT },
  // RootId: { label: 'Root ID', queryRuleFormType: QueryRuleFormType.TEXT },
  SectionName: { queryRuleFormType: QueryRuleFormType.TEXT },
  SectionType: { queryRuleFormType: QueryRuleFormType.TEXT },
  // SectionsExtra: { queryRuleFormType: QueryRuleFormType.TEXT },
  // ShowId: { queryRuleFormType: QueryRuleFormType.TEXT },
  Slug: { queryRuleFormType: QueryRuleFormType.TEXT },
  // SubsectionsExtra: { queryRuleFormType: QueryRuleFormType.TEXT },
  // SubjectCode: { queryRuleFormType: QueryRuleFormType.TEXT },
  // SubjectCodeName: { queryRuleFormType: QueryRuleFormType.TEXT },
  // Template: { queryRuleFormType: QueryRuleFormType.TEXT },
  // TopicCode: { queryRuleFormType: QueryRuleFormType.TEXT },
  // UpstreamOrigin: { queryRuleFormType: QueryRuleFormType.TEXT },
  UpstreamOriginId: { label: 'Content ID', queryRuleFormType: QueryRuleFormType.TEXT },
  // WsjSection: { label: 'WSJ Section', queryRuleFormType: QueryRuleFormType.TEXT },
  // WsjSubsection: { label: 'WSJ Subsection', queryRuleFormType: QueryRuleFormType.TEXT },
  // WsjdnPackage: { label: 'WSJDN Package', queryRuleFormType: QueryRuleFormType.TEXT },
  LiveDate: { queryRuleFormType: QueryRuleFormType.DATE },
  // PrintPublicationDate: { queryRuleFormType: QueryRuleFormType.DATE },
  // PublishedDate: { queryRuleFormType: QueryRuleFormType.DATE },
  UpdatedDate: { queryRuleFormType: QueryRuleFormType.DATE }
  // Group: {
  //   queryRuleFormType: QueryRuleFormType.SELECT,
  //   formComponentProps: {
  //     options: [
  //       { label: 'WSJ', value: 'WSJ' },
  //       { label: 'MarketWatch', value: 'MarketWatch' },
  //       { label: 'Barrons', value: 'Barrons' },
  //       { label: 'Newswires', value: 'Newswires' },
  //       { label: 'MansionGlobal', value: 'MansionGlobal' }
  //     ]
  //   }
  // }
};
