import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ErrorPage, useAuth } from '@screentone/addon-auth-wrapper';

import { ResponsiveLoader } from 'components';
import { AddEntityToCollection } from 'components/context-menu-actions/add-entity-to-collection/AddEntityToCollection';
import RemoveEntityFromCollection from 'components/context-menu-actions/remove-entity-from-collection/RemoveEntityFromCollection';
import { ViewPublishedURL } from 'components/context-menu-actions/view-published-url/ViewPublishedURL';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import {
  ContextMenuActionsProvider,
  MenuActions,
  MenuActionsKeys
} from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { DataModelProvider, DataModelType } from 'contexts/datamodel/DataModelContext';
import { DragAndDropProvider } from 'contexts/drag-and-drop/DragAndDropContext';
import { SummarianProvider } from 'contexts/summarian/SummarianContext';
import { TrashProvider } from 'contexts/trash/TrashContext';
import {
  ArticleItem,
  BaseMetadata,
  ContentUnion,
  ModuleContainer,
  NewsletterPageContainer,
  PageType,
  PageTypeSetting,
  usePageTypeSettingByPublicationKeyPageTypeQuery
} from 'data/generated/graphql';
import { defaultNewsletterModuleContainer } from 'features/newsletters/constants';
import { useEnabledNewsletter } from 'features/newsletters/hooks/useEnabledNewsletter';
import { useNewsletterTypes } from 'features/newsletters/hooks/useNewsletterTypes';
import { NewsletterEdit } from 'features/newsletters/NewsletterEdit';
import { useConvertedProperty } from 'hooks';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { safelyParseContent } from 'utils/temp';

const NewsletterEditPage = () => {
  const currentProperty = useConvertedProperty();
  const { app } = useAuth();

  const [searchParams] = useSearchParams();
  const newsletterId = searchParams.get('newsletter');

  const { newsletterType, isFetching } = useNewsletterTypes({
    publicationKey: currentProperty ?? '',
    newsletterId: newsletterId ?? ''
  });

  const { isLoading, enabledNewsletter } = useEnabledNewsletter();

  const { data: pageTypeSettingsResp } = usePageTypeSettingByPublicationKeyPageTypeQuery(
    { publicationKey: currentProperty ?? '', pageType: PageType.Newsletter },
    { enabled: !!currentProperty }
  );

  const pageTypeSetting = pageTypeSettingsResp?.pageTypeSettingByPublicationKeyPageType as PageTypeSetting | undefined;

  const getNewsletterId = useCallback(
    () => `UCS${app.env === 'prd' ? 'P' : 'D'}_${currentProperty?.toUpperCase()}_NEWSLETTER_${newsletterId}`,
    [newsletterId, currentProperty, app.env]
  );

  const newsletterModuleContainer = useMemo<ModuleContainer>(
    () =>
      ({
        ...defaultNewsletterModuleContainer,
        ...(pageTypeSetting
          ? { attributes: { pageModule: pageTypeSetting.layouts[0].availableLayoutModules[0].defaultModule } }
          : {})
      } as ModuleContainer),
    [pageTypeSetting]
  );

  const contextActions: MenuActions = useMemo<MenuActions>(
    () => ({
      [MenuActionsKeys.DraftArticle]: [
        {
          component: ({ hierarchyId }) => (
            <RemoveEntityFromCollection hierarchyId={hierarchyId!} text="Remove from module" />
          ),
          withDivider: 'bottom'
        },
        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        }
      ],
      [MenuActionsKeys.ArticleSearch]: [
        {
          component: ({ hierarchyId, entity }) => {
            const module = { ...newsletterModuleContainer };
            module.collection = [entity as ContentUnion];

            return <AddEntityToCollection entity={module} hierarchyId={hierarchyId!} textDisplay="Add to Campaign" />;
          },
          withDivider: 'bottom'
        },

        {
          component: ({ entity }) => {
            const article = entity as ArticleItem;
            return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
          }
        }
      ]
    }),
    [newsletterModuleContainer]
  );

  const root = useMemo<NewsletterPageContainer>(
    () => ({
      subtype: 'NewsletterPage',
      type: 'Page',
      collection: [newsletterModuleContainer],
      attributes: {
        campaignId: `${Math.random()}`.substring(2, 7),
        newsletterId: newsletterType?.id ? +newsletterType.id : 0,
        preHeaderText: newsletterType?.defaultCampaignDetails?.pre_header_text ?? '',
        replyToEmail: newsletterType?.defaultCampaignDetails?.reply_to_email ?? '',
        senderName: newsletterType?.defaultCampaignDetails?.sender_name ?? '',
        subject: newsletterType?.name ?? ''
      }
    }),
    [newsletterModuleContainer, newsletterType]
  );

  const metadata = useMemo<BaseMetadata>(
    () => ({
      allessehId: getNewsletterId(),
      name: newsletterType?.name ?? '',
      publicationKey: currentProperty ?? '',
      createdUtc: 0,
      creatorUser: { djUserId: '' },
      revisorUser: { djUserId: '' }
    }),
    [newsletterType, currentProperty, getNewsletterId]
  );

  if (isFetching || isLoading) {
    return (
      <PageRootContainer>
        <ResponsiveLoader />
      </PageRootContainer>
    );
  }

  if (!currentProperty || !newsletterId || !newsletterType || !enabledNewsletter) {
    return <ErrorPage type="404" />;
  }

  return (
    <SummarianProvider>
      <DataModelProvider type={DataModelType.NewsletterDTO} root={root} metadata={metadata}>
        <TrashProvider>
          <DragAndDropProvider>
            <PageRootContainer>
              <ContextMenuActionsProvider actions={contextActions}>
                <NewsletterEdit newsletterType={newsletterType} pageTypeSetting={pageTypeSetting} />
              </ContextMenuActionsProvider>
            </PageRootContainer>
          </DragAndDropProvider>
        </TrashProvider>
      </DataModelProvider>
    </SummarianProvider>
  );
};

export default NewsletterEditPage;
