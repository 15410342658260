import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Alert, IconInfo, Responsive, Subnav, Wrapper } from '@screentone/core';
import classnames from 'classnames';

import { useAlert } from 'contexts/alert/useAlert';
import { useIssue } from 'contexts/issue/useIssue';
import { usePagePublish } from 'contexts/page-publish/usePagePublish';
import { Artifact } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';
import { useIsPublisher } from 'hooks/roles';
import styles from './UcsSubnav.module.scss';

export const UcsSubnav = () => {
  const { currentProperty } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { alertState, removeAlert } = useAlert();
  const { clearValidationErrorsInModules } = usePagePublish();
  const { clearErrorState: clearIssueErrorState } = useIssue();
  const { data: publicationSettingsResp } = usePublicationSettings();

  const handleSubnavClick = (path: string) => () => {
    navigate(`/${currentProperty ?? ''}${path}`);
  };

  const handleAlertDismiss = () => {
    clearValidationErrorsInModules();
    clearIssueErrorState();
    removeAlert();
  };
  const isPublisher = useIsPublisher();

  const publicationSettings = publicationSettingsResp?.publicationSetting;
  const arePagesEnabled = publicationSettings?.enabledArtifacts.some((artifact) => artifact === Artifact.Page);
  const areCollectionsEnabled = publicationSettings?.enabledArtifacts.some(
    (artifact) => artifact === Artifact.Collection
  );
  const areOffPlatformEnabled = publicationSettings?.enabledArtifacts.some(
    (artifact) => artifact === Artifact.OffPlatform
  );
  const areItpIssuesEnabled = publicationSettings?.enabledArtifacts.some((artifact) => artifact === Artifact.ItpIssue);
  const areNewslettersEnabled = publicationSettings?.enabledArtifacts.some(
    (artifact) => artifact === Artifact.Newsletter
  );
  const areBannersEnabled = publicationSettings?.enabledArtifacts.some((artifact) => artifact === Artifact.Banner);

  return (
    <Wrapper className={styles.ucsSubnavWrapper}>
      <Responsive sm lg className={styles.ucsSubnav}>
        <Subnav margin={{ bottom: 'none' }} data-testid="ucs-subnav-tabs" className={styles.ucsSubnavTabs}>
          {isPublisher && arePagesEnabled && (
            <Subnav.Item
              active={currentProperty ? [`/${currentProperty}/`, `/${currentProperty}`].includes(pathname) : false}
              onClick={handleSubnavClick('/')}
              data-testid="ucs-subnav-pages-tab"
            >
              Pages
            </Subnav.Item>
          )}
          {areCollectionsEnabled && (
            <Subnav.Item
              active={currentProperty ? pathname === `/${currentProperty}/collections` : false}
              onClick={handleSubnavClick('/collections')}
              data-testid="ucs-subnav-collections-tab"
            >
              Collections
            </Subnav.Item>
          )}
          {areOffPlatformEnabled && (
            <Subnav.Item
              active={currentProperty ? pathname === `/${currentProperty}/off-platform` : false}
              onClick={handleSubnavClick('/off-platform')}
              data-testid="ucs-subnav-off-platform-tab"
            >
              Off-Platform
            </Subnav.Item>
          )}
          {isPublisher && areItpIssuesEnabled && (
            <Subnav.Item
              active={pathname === `/${currentProperty}/issues`}
              onClick={handleSubnavClick('/issues')}
              data-testid="ucs-subnav-itp-issues-tab"
            >
              ITP Issues
            </Subnav.Item>
          )}
          {isPublisher && areNewslettersEnabled && (
            <Subnav.Item
              active={pathname === `/${currentProperty}/newsletters`}
              onClick={handleSubnavClick('/newsletters')}
              data-testid="ucs-subnav-news-letters-tab"
            >
              Newsletters
            </Subnav.Item>
          )}
          {isPublisher && areBannersEnabled && (
            <Subnav.Item
              active={pathname === `/${currentProperty}/banners`}
              onClick={handleSubnavClick('/banners')}
              data-testid="ucs-subnav-banners-tab"
            >
              Banners
            </Subnav.Item>
          )}
        </Subnav>
      </Responsive>
      {alertState.message && (
        <Alert
          className={classnames(styles.alert, alertState.wrapNewlines ? styles.wrapText : '')}
          icon={IconInfo as SvgComponent}
          type={alertState.type}
          onDismiss={handleAlertDismiss}
          autoDismiss={alertState.autoDismiss}
          data-testid="ucs-subnav-alert"
        >
          {alertState.message}
        </Alert>
      )}
    </Wrapper>
  );
};
