import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Dialog, Divider, FormLabel, Input, Typography, Wrapper } from '@screentone/core';

import { WidePageWrapper } from 'components';
import { useAlert } from 'contexts/alert/useAlert';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import {
  ArticleItem,
  NewsletterDto,
  NewsletterListItem,
  NewsletterPageContainer,
  PageTypeSetting
} from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { safelyParseContent } from 'utils/temp';
import { NewsletterMultitabSection } from './components/newsletter-multitab-section/NewsletterMultitabSection';
import { TitleHeader } from './components/title-header/TitleHeader';
import { useNewsletterPublish } from './hooks/useNewsletterPublish';
import styles from './NewsletterEdit.module.scss';

export const NewsletterEdit = ({
  newsletterType,
  pageTypeSetting
}: {
  newsletterType: NewsletterListItem;
  pageTypeSetting: PageTypeSetting | undefined;
}) => {
  const { root: data, metadata, renderEntity, setNewRoot } = useDataModelContext<NewsletterPageContainer>();

  const { data: publicationSettingResp } = usePublicationSettings();

  const { handleSendTest, handleSendNow, handleSchedule } = useNewsletterPublish(
    publicationSettingResp?.publicationSetting
  );

  const currentProperty = useConvertedProperty();

  const { alertError } = useAlert();

  const navigate = useNavigate();

  const [sendNowOpen, setSendNowOpen] = useState<boolean>(false);

  const collection = useMemo(() => data.collection[0]?.collection ?? [], [data.collection]);
  const dataAttributes = useMemo(() => data.attributes, [data.attributes]);

  const getDefaultCampaignSubject = useCallback(() => {
    if (data.collection.length === 0) {
      return metadata.name;
    }

    const articleContent: AllessehContent | null =
      data.collection[0].collection.length !== 0
        ? safelyParseContent((data.collection[0].collection[0] as ArticleItem).content)
        : null;

    const headline: string = articleContent?.data.attributes.headline?.text ?? '';
    return `${newsletterType.name}: ${headline}`;
  }, [data.collection, metadata.name, newsletterType.name]);

  const validatePageSetting = () => {
    if (!pageTypeSetting) {
      return null;
    }

    if (data.collection.length < pageTypeSetting.layouts[0].availableLayoutModules[0].minimumAllowed) {
      return `Newsletters must contain at least ${pageTypeSetting.layouts[0].availableLayoutModules[0].minimumAllowed} page module(s).`;
    }

    if (data.collection.length > pageTypeSetting.layouts[0].availableLayoutModules[0].maximumAllowed) {
      return `Newsletters must contain no more than ${pageTypeSetting.layouts[0].availableLayoutModules[0].minimumAllowed} page module(s).`;
    }

    if (data.collection[0].collection.length < pageTypeSetting.treatmentTypeSettings[0].minRequiredContentItems) {
      return `Validation error: Newsletters must contain at least ${pageTypeSetting.treatmentTypeSettings[0].minRequiredContentItems} item(s)`;
    }

    if (data.collection[0].collection.length > pageTypeSetting.treatmentTypeSettings[0].maxRequiredContentItems) {
      return `Validation error: Newsletters must contain no more than ${pageTypeSetting.treatmentTypeSettings[0].maxRequiredContentItems} item(s)`;
    }

    return null;
  };

  const validateNewsletter = () => {
    if (
      data.attributes.senderName === '' ||
      data.attributes.replyToEmail === '' ||
      data.attributes.subject === '' ||
      data.attributes.preHeaderText === ''
    ) {
      return 'Cannot send newsletter. Please check that all fields are completed.';
    }

    return validatePageSetting();
  };

  const getDefaultCampaignPreHeader = () => {
    const defaultName = metadata.name;

    if (data.collection.length === 0) {
      return defaultName;
    }

    const { collection } = data.collection[0];

    if (collection.length === 0) {
      return defaultName;
    }

    const articleContent: AllessehContent | null =
      data.collection[0].collection.length !== 0
        ? safelyParseContent((data.collection[0].collection[0] as ArticleItem).content)
        : null;

    const content = articleContent?.data.attributes.standfirst?.content;
    if (!content?.length) {
      return defaultName;
    }

    return content[0].text;
  };

  useEffect(() => {
    if (collection.length) {
      const newRoot = {
        ...data,
        attributes: {
          ...dataAttributes,
          subject: getDefaultCampaignSubject(),
          preHeaderText: getDefaultCampaignPreHeader()
        }
      };
      setNewRoot(newRoot);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection]);

  const sendNowClick = async () => {
    const messageError = validateNewsletter();
    if (messageError) {
      alertError(messageError);
      setSendNowOpen(false);
      return;
    }

    const publishedNewsletter = await handleSendNow();

    setSendNowOpen(false);

    if (publishedNewsletter) {
      navigate(`/${currentProperty}/newsletters/${publishedNewsletter.metadata.allessehId}`);
    }
  };

  const handleScheduleClick = async (publishUtc: number) => {
    const messageError = validateNewsletter();
    if (messageError) {
      alertError(messageError);
      return;
    }

    const scheduledNewsletter = await handleSchedule(publishUtc);

    if (scheduledNewsletter) {
      navigate(
        `/${currentProperty}/newsletters/scheduled/${scheduledNewsletter.allessehId}/${scheduledNewsletter.publishUtc}`
      );
    }
  };

  const handleSendTestClick = async (emails: string) => {
    const messageError = validatePageSetting();

    if (messageError) {
      alertError(messageError);
      return;
    }

    await handleSendTest(emails);
  };

  const updateCustomizableContent = (e: Event, fieldName: string) => {
    const newRoot = {
      ...data,
      attributes: {
        ...data.attributes,
        [fieldName]: (e.target as HTMLInputElement).value
      }
    };
    setNewRoot(newRoot);
  };

  return (
    <WidePageWrapper>
      <TitleHeader
        readOnly={false}
        newsletterName={metadata.name}
        handleSendNowClick={() => setSendNowOpen(true)}
        handleScheduleClick={handleScheduleClick}
        handleSendTestClick={handleSendTestClick}
        publicationSetting={publicationSettingResp?.publicationSetting}
      />
      <Divider margin={{ top: 'md' }} />

      <div className={styles.container}>
        <Wrapper className={styles.draftWrapper} margin={{ right: 'md' }} padding={{ top: 'mlg' }}>
          {renderEntity(data, { hierarchyId: '' })}
          <Wrapper>
            <Box margin={{ all: 'md' }}>
              <Box.Title data-testid="newsletter-edit-custom-content">Custom Content</Box.Title>
              <Box.Content>
                <FormLabel labelPosition="top" label="Sender Name" margin={{ bottom: 'md' }} fullWidth>
                  <Input
                    data-testid="newsletter-edit-sender-name"
                    value={data.attributes.senderName}
                    onChange={(evt: Event) => updateCustomizableContent(evt, 'senderName')}
                  />
                </FormLabel>
                <FormLabel labelPosition="top" label="Reply-To Email" margin={{ bottom: 'md' }} fullWidth>
                  <Input
                    data-testid="newsletter-edit-reply-email"
                    value={data.attributes.replyToEmail}
                    onChange={(evt: Event) => updateCustomizableContent(evt, 'replyToEmail')}
                  />
                </FormLabel>
                <FormLabel labelPosition="top" label="Subject" margin={{ bottom: 'md' }} fullWidth>
                  <Input
                    data-testid="newsletter-edit-subject-line"
                    value={data.attributes.subject}
                    onChange={(evt: Event) => updateCustomizableContent(evt, 'subject')}
                  />
                </FormLabel>
                <FormLabel labelPosition="top" label="Pre-Header Text" margin={{ bottom: 'md' }} fullWidth>
                  <Input
                    data-testid="newsletter-edit-header-text"
                    value={data.attributes.preHeaderText}
                    onChange={(evt: Event) => updateCustomizableContent(evt, 'preHeaderText')}
                  />
                </FormLabel>
              </Box.Content>
            </Box>
          </Wrapper>
        </Wrapper>
        <div className={styles.newsletterDivider} />
        <Wrapper className={styles.multitabWrapper} margin={{ left: 'md' }} padding={{ top: 'mlg' }}>
          <NewsletterMultitabSection
            newsletterName={metadata.name}
            campaign={{ root: data, metadata } as NewsletterDto}
          />
        </Wrapper>
      </div>

      <Dialog status={sendNowOpen ? 'open' : 'closed'} onDismiss={() => setSendNowOpen(false)}>
        <Dialog.Title id="dialogTitle">Send Campaign</Dialog.Title>
        <Dialog.Content id="dialogDesc">
          <Typography variant="header4" data-testid="newsletter-send-now-msg">
            {data.attributes.subject}
          </Typography>
          <Typography>Are you sure you want to send this immediately?</Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Button secondary onClick={() => setSendNowOpen(false)} data-testid="newsletter-cancel-send">
            Cancel
          </Button>
          <Button primary onClick={sendNowClick} data-testid="newsletter-send">
            Send
          </Button>
        </Dialog.Actions>
      </Dialog>
    </WidePageWrapper>
  );
};
