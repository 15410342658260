import React from 'react';
import { Button, IconEdit, Tooltip } from '@screentone/core';

import styles from './EditAltSummOption.scss';

interface EditAltSummOptionProps {
  handleEditAltSumm: () => void;
  shouldDisplay: boolean | undefined;
  disableAltSummEdit: boolean;
}

const summarianNotReadyMessage = `There was an issue loading Summarian or you do not have Summarian access.
Please contact the #curation team on Slack.`;

export const EditAltSummOption = ({ handleEditAltSumm, shouldDisplay, disableAltSummEdit }: EditAltSummOptionProps) => {
  const EditAltSummButton = (
    <Button
      data-testid="edit-alt-summ-option-button"
      disabled={!window.isSummarianReady}
      tertiary
      icon={IconEdit as SvgComponent}
      onClick={() => handleEditAltSumm()}
    >
      Edit Alt-Summ
    </Button>
  );

  return shouldDisplay && !disableAltSummEdit ? (
    <>
      {window.isSummarianReady ? (
        EditAltSummButton
      ) : (
        <Tooltip data-testid="edit-alt-summ-option-tooltip">
          <Tooltip.Content
            data-testid="edit-alt-summ-option-tooltip-content"
            id="altSumModalTooltip"
            position="bottom"
            className={styles.altSumTooltip}
            size="lg"
          >
            {summarianNotReadyMessage}
          </Tooltip.Content>
          <Tooltip.Trigger data-testid="edit-alt-summ-option-tooltip-trigger" aria-describedby="altSumModalTooltip">
            {EditAltSummButton}
          </Tooltip.Trigger>
        </Tooltip>
      )}
    </>
  ) : null;
};
