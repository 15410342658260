import { useEffect, useMemo, useState } from 'react';
import { Button } from '@screentone/core';
import { format } from 'date-fns-tz';

import { PageError } from 'contexts/alert/AlertContext';
import { formatAlertMessage, useAlert } from 'contexts/alert/useAlert';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import {
  AllessehContentType,
  BannerDto,
  BannerDtoPreviewLinkInput,
  BannerDtoUpdateInput,
  BannerModuleContainer,
  ScheduledContentCancelInput,
  ScheduledContentCreateInput,
  ScheduledContentRescheduleInput,
  ScheduledContentUpdateInput,
  useBannerDtoPreviewMutation,
  useBannerDtoUpdateMutation,
  useCancelScheduledContentMutation,
  useGetScheduledContentByTimeWithAllessehIdQuery,
  useRescheduleContentMutation,
  useScheduleContentMutation,
  useUpdateScheduledContentMutation
} from 'data/generated/graphql';
import { useConvertedProperty } from 'hooks';
import { useSocketNotificationsScheduledContent } from 'hooks/useSocketNotificationsPageContent';
import { formatDateToShortDateTime, isWithinTimeInterval, TIMES } from 'utils/time';
import { BannerTabs } from '../components/banner-multitab-section/BannerMultitabSection';

const UPDATE_ERROR_MESSAGE = 'Could not publish the banner.';
const PREVIEW_ERROR_MESSAGE = 'Could not set up previewing for the banner.';
const SCHEDULE_ERROR_MESSAGE = 'Could not schedule the banner.';
const RESCHEDULE_ERROR_MESSAGE = 'Could not reschedule the banner.';
const SCHEDULE_UPDATE_ERROR_MESSAGE = 'Could not update the scheduled banner.';

export const useBannerPublish = () => {
  const { alertError, alertSuccess, removeAlert, alertModules } = useAlert();
  const [isRecentlyUpdated, setIsRecentlyUpdated] = useState(false);
  const [isRecentlyPublish, setIsRecentlyPublish] = useState(false);
  const [savedForScheduleEdit, setSavedForScheduleEdit] = useState<BannerDto | null>(null);
  const currentProperty = useConvertedProperty();
  const {
    metadata,
    getDTO,
    hasModelChangedOnce,
    resetModelChanged,
    deleteResolvedFieldsFromExternalDTO,
    setNewRoot,
    setNewMetadata
  } = useDataModelContext<BannerModuleContainer>();

  const { alertWarning } = useAlert();
  const [hasRecentlyPublishedScheduledContent, setHasRecentlyPublishedScheduledContent] = useState<boolean>(false);
  const [soonToBePublishedScheduledContentPublishUtc, setSoonToBePublishedScheduledContentPublishUtc] = useState<
    number | null
  >(null);
  const [tabToChangeTo, setTabToChangeTo] = useState<BannerTabs>(BannerTabs.ContentSearch);
  const oneHourFromNow = useMemo(() => new Date().getTime() + TIMES.ONE_HOUR, []);
  const { data: contentReadyToPublish, refetch: refetchScheduledContentByTime } =
    useGetScheduledContentByTimeWithAllessehIdQuery({
      allessehId: metadata.allessehId,
      maxTimestamp: oneHourFromNow
    });

  const { mutateAsync: bannerUpdateMutateAsync, isLoading: isPublishing } = useBannerDtoUpdateMutation();
  const { mutateAsync: generatePreviewLinkMutateAsync, isLoading: isCreatingPreview } = useBannerDtoPreviewMutation();
  const { mutateAsync: scheduleAsync, isLoading: isScheduling } = useScheduleContentMutation();
  const { mutateAsync: rescheduleAsync, isLoading: isRescheduling } = useRescheduleContentMutation();
  const { mutateAsync: updateScheduleAsync, isLoading: isUpdatingSchedule } = useUpdateScheduledContentMutation();
  const { mutateAsync: cancelScheduleAsync, isLoading: isCanceling } = useCancelScheduledContentMutation();

  const showEditSchedule = (history: BannerDto) => {
    const current = getDTO() as BannerDto;
    setSavedForScheduleEdit(current);
    setNewRoot(history.root);
    setNewMetadata(history.metadata);
  };

  const hideEditSchedule = () => {
    if (savedForScheduleEdit) {
      setNewRoot(savedForScheduleEdit.root);
      setNewMetadata(savedForScheduleEdit.metadata);
      setSavedForScheduleEdit(null);
    }
  };

  const handlePublish = async () => {
    const bannerDTO = getDTO(true) as BannerDto;

    removeAlert();

    const updateInput: BannerDtoUpdateInput = {
      bannerDTO,
      publicationKey: currentProperty ?? ''
    };

    try {
      await bannerUpdateMutateAsync({ bannerDTOUpdateInput: updateInput });

      resetModelChanged();
      setIsRecentlyUpdated(true);
      setIsRecentlyPublish(true);
      alertSuccess('Banner successfully published');
    } catch (e: unknown) {
      if (e instanceof Error) {
        if (e.message.startsWith('BANNER_VALIDATION_ERROR#')) {
          const errorJson = e.message.replace('BANNER_VALIDATION_ERROR#', '');
          const pageError = JSON.parse(errorJson) as PageError;
          alertError(`${UPDATE_ERROR_MESSAGE}\n${formatAlertMessage(pageError)}`, { wrapNewlines: true });
          alertModules(pageError.validationError!);
        } else {
          alertError(`${UPDATE_ERROR_MESSAGE} ${e.message}`);
        }
      } else {
        alertError(UPDATE_ERROR_MESSAGE);
      }
    }
  };

  const handlePreview = async (banner?: BannerDto) => {
    let bannerDTO = banner ?? getDTO(true);
    bannerDTO = deleteResolvedFieldsFromExternalDTO(bannerDTO);

    const bannerDTOPreviewLinkInput: BannerDtoPreviewLinkInput = {
      publicationKey: currentProperty ?? '',
      bannerDTO
    };
    try {
      const resp = await generatePreviewLinkMutateAsync({
        bannerDTOPreviewLinkInput
      });
      if (resp.getBannerPreviewLink) {
        window.open(resp.getBannerPreviewLink, '_blank');
      } else {
        alertError(PREVIEW_ERROR_MESSAGE);
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        if (e.message.startsWith('BANNER_VALIDATION_ERROR#')) {
          const errorJson = e.message.replace('BANNER_VALIDATION_ERROR#', '');
          const pageError = JSON.parse(errorJson) as PageError;
          alertError(`${PREVIEW_ERROR_MESSAGE}\n${formatAlertMessage(pageError)}`, { wrapNewlines: true });
          alertModules(pageError.validationError!);
        } else {
          alertError(`${PREVIEW_ERROR_MESSAGE} ${e.message}`);
        }
      } else {
        alertError(PREVIEW_ERROR_MESSAGE);
      }
    }
  };

  const handleSchedule = async (publishUtc: number) => {
    const banner = getDTO(true);

    banner.metadata.publishUtc = publishUtc;

    removeAlert();

    const scheduleInput: ScheduledContentCreateInput = {
      allessehId: banner.metadata.allessehId,
      contentType: AllessehContentType.Banner,
      publishUtc,
      body: banner
    };

    try {
      await scheduleAsync({ scheduledContentCreateInput: scheduleInput, publicationKey: currentProperty ?? '' });

      resetModelChanged();
      setIsRecentlyUpdated(true);
      alertSuccess(`Scheduled banner for ${format(publishUtc, 'MMM d, yyyy h:mm aaa z')}.`);

      if (soonToBePublishedScheduledContentPublishUtc && soonToBePublishedScheduledContentPublishUtc > publishUtc) {
        setSoonToBePublishedScheduledContentPublishUtc(publishUtc);
      } else if (
        !soonToBePublishedScheduledContentPublishUtc &&
        isWithinTimeInterval(publishUtc, new Date().getTime(), 'hour')
      ) {
        setSoonToBePublishedScheduledContentPublishUtc(publishUtc);
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        if (e.message.startsWith('BANNER_VALIDATION_ERROR#')) {
          const errorJson = e.message.replace('BANNER_VALIDATION_ERROR#', '');
          const pageError = JSON.parse(errorJson) as PageError;
          alertError(`${SCHEDULE_ERROR_MESSAGE}\n${formatAlertMessage(pageError)}`, { wrapNewlines: true });
          alertModules(pageError.validationError!);
        } else {
          alertError(`${SCHEDULE_ERROR_MESSAGE} ${e.message}`);
        }
      } else {
        alertError(SCHEDULE_ERROR_MESSAGE);
      }
    }
  };

  const handleReschedule = async (publishUtc: number) => {
    const banner = getDTO(true);

    const previousPublishUtc = banner.metadata.publishUtc!;
    banner.metadata.publishUtc = publishUtc;

    const input: ScheduledContentRescheduleInput = {
      allessehId: banner.metadata.allessehId,
      contentType: AllessehContentType.Banner,
      previousPublishUtc,
      newPublishUtc: publishUtc,
      body: banner
    };

    try {
      await rescheduleAsync({
        publicationKey: currentProperty ?? '',
        scheduledContentRescheduleInput: input
      });

      setIsRecentlyUpdated(true);
      alertSuccess(`Rescheduled banner for ${format(publishUtc, 'MMM d, yyyy h:mm aaa z')}.`);
      hideEditSchedule();
      await refetchScheduledContentByTime();
    } catch (e: unknown) {
      if (e instanceof Error) {
        if (e.message.startsWith('BANNER_VALIDATION_ERROR#')) {
          const errorJson = e.message.replace('BANNER_VALIDATION_ERROR#', '');
          const pageError = JSON.parse(errorJson) as PageError;
          alertError(`${RESCHEDULE_ERROR_MESSAGE}\n${formatAlertMessage(pageError)}`, { wrapNewlines: true });
          alertModules(pageError.validationError!);
        } else {
          alertError(`${RESCHEDULE_ERROR_MESSAGE} ${e.message}`);
        }
      } else {
        alertError(RESCHEDULE_ERROR_MESSAGE);
      }
    }
  };

  const handleUpdateSchedule = async () => {
    const banner = getDTO(true);

    const input: ScheduledContentUpdateInput = {
      allessehId: banner.metadata.allessehId,
      publishUtc: banner.metadata.publishUtc!,
      contentType: AllessehContentType.Banner,
      body: banner
    };

    try {
      await updateScheduleAsync({
        publicationKey: currentProperty ?? '',
        scheduledContentUpdateInput: input
      });

      setIsRecentlyUpdated(true);
      alertSuccess('Scheduled banner has been updated.');
      hideEditSchedule();
    } catch (e: unknown) {
      if (e instanceof Error) {
        if (e.message.startsWith('BANNER_VALIDATION_ERROR#')) {
          const errorJson = e.message.replace('BANNER_VALIDATION_ERROR#', '');
          const pageError = JSON.parse(errorJson) as PageError;
          alertError(`${SCHEDULE_UPDATE_ERROR_MESSAGE}\n${formatAlertMessage(pageError)}`, {
            wrapNewlines: true
          });
          alertModules(pageError.validationError!);
        } else {
          alertError(`${SCHEDULE_UPDATE_ERROR_MESSAGE} ${e.message}`);
        }
      } else {
        alertError(SCHEDULE_UPDATE_ERROR_MESSAGE);
      }
    }
  };

  const handleCancelSchedule = async () => {
    const banner = getDTO(true);

    const input: ScheduledContentCancelInput = {
      allessehId: banner.metadata.allessehId,
      publishUtc: banner.metadata.publishUtc!
    };

    try {
      await cancelScheduleAsync({ publicationKey: currentProperty ?? '', scheduledContentCancelInput: input });

      setIsRecentlyUpdated(true);
      alertSuccess('Scheduled banner has been canceled');
      hideEditSchedule();
      if (
        isWithinTimeInterval(
          soonToBePublishedScheduledContentPublishUtc ?? 0,
          banner.metadata.publishUtc ?? 0,
          'second'
        )
      ) {
        setSoonToBePublishedScheduledContentPublishUtc(null);
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        alertError(`Could not cancel the scheduled banner. ${e.message}`);
      } else {
        alertError('Could not cancel the scheduled banner.');
      }
    }
  };

  const handleScheduledContentPublished = async (publishUtc: number) => {
    setSoonToBePublishedScheduledContentPublishUtc(null);
    setIsRecentlyPublish(true);
    setIsRecentlyUpdated(true);
    setHasRecentlyPublishedScheduledContent(true);
    await refetchScheduledContentByTime();
    const formattedDate = formatDateToShortDateTime(publishUtc);
    const tabChangeButton = (
      <Button
        padding={{ all: 'none' }}
        margin={{ all: 'none' }}
        tertiary
        onClick={() => setTabToChangeTo(BannerTabs.PublishHistory)}
      >
        View in History
      </Button>
    );
    alertWarning(
      <div>
        A scheduled publish for {formattedDate} has gone live.{' '}
        {hasModelChangedOnce ? tabChangeButton : 'Refresh the page to see changes.'}
      </div>
    );
  };

  useSocketNotificationsScheduledContent({
    canSave: hasModelChangedOnce,
    pageVariant: 'banners',
    isPreviewDraftPage: false,
    soonToBePublishedScheduledContentPublishUtc,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setTabToChangeTo,
    setHasRecentlyPublished: setIsRecentlyPublish,
    setHasRecentlyPublishedScheduledContent,
    setSoonToBePublishedScheduledContentPublishUtc,
    handleScheduledContentPublished
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (isRecentlyPublish) {
      timeout = setTimeout(() => {
        setIsRecentlyPublish(false);
      }, 3000);
    } else if (isRecentlyUpdated) {
      timeout = setTimeout(() => {
        setIsRecentlyUpdated(false);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isRecentlyPublish, isRecentlyUpdated]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (hasRecentlyPublishedScheduledContent) {
      timeout = setTimeout(() => {
        setHasRecentlyPublishedScheduledContent(false);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [hasRecentlyPublishedScheduledContent]);

  useEffect(() => {
    if (contentReadyToPublish) {
      const soonToBePublishedContent = contentReadyToPublish.scheduledContentByTimeWithAllessehId;
      if (soonToBePublishedContent.length > 0) {
        setSoonToBePublishedScheduledContentPublishUtc(soonToBePublishedContent[0].publishUtc);
      }
    }
  }, [contentReadyToPublish]);

  return {
    hasBannerChanged: hasModelChangedOnce,
    isMutationInProgress:
      isPublishing || isCreatingPreview || isScheduling || isRescheduling || isUpdatingSchedule || isCanceling,
    handlePublish,
    handlePreview,
    handleSchedule,
    handleReschedule,
    handleUpdateSchedule,
    handleCancelSchedule,
    isInEditMode: !!savedForScheduleEdit,
    showEditSchedule,
    hideEditSchedule,
    savedForScheduleEdit,
    isRecentlyUpdated,
    isRecentlyPublish,

    hasRecentlyPublishedScheduledContent,
    soonToBePublishedScheduledContentPublishUtc,
    tabToChangeTo,
    setTabToChangeTo
  };
};
