/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useEffect, useMemo } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useAuth } from '@screentone/addon-auth-wrapper';
import {
  Box,
  Button,
  Divider,
  Dropdown,
  Group,
  IconExternalLink,
  IconMinusCircle,
  IconThreeDotsVer,
  IconTrash,
  Wrapper
} from '@screentone/core';
import classnames from 'classnames';

import { AltSummModal } from 'components/altsumm-modal/AltSummModal';
import { EditAltSummOption } from 'components/altsumm-modal/components/edit-alt-summ-option/EditAltSummOption';
import { CopyPublishedURL } from 'components/context-menu-actions/copy-published-url/CopyPublishedURL';
import { OpenInNewsPress } from 'components/context-menu-actions/open-in-newspress/OpenInNewsPress';
import ExternalLink, { ExternalLinkContentType } from 'components/external-link/ExternalLink';
import { NewsgridButton } from 'components/newsgrid-button/NewsgridButton';
import { useIssue } from 'contexts/issue/useIssue';
import { usePagePublish } from 'contexts/page-publish/usePagePublish';
import { AltSummVariant } from 'contexts/summarian/SummarianContext';
import { useSummarianContext } from 'contexts/summarian/useSummarianContext';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { useAltSummModal } from 'hooks/useAltSummModal';
import { useContentConstants } from 'hooks/useContentConstants';
import { buildLiveCoverageUrlSettings } from 'utils/cms';
import styles from './PageContentCard.module.scss';
import { ContentCard } from '../article-card/ContentCard';
import { addModuleItem } from '../page-draft-section/PageDraftSectionUtils';

import { isAltSummDisabled } from 'components/context-menu-actions/edit-alt-summ/EditAltSumm.utils';

interface PageContentCardProps {
  content: AllessehContent;
  disableAltSumm?: boolean;
  onExcludeFromQuery?: (() => void) | null;
  onRemove?: (() => void) | null;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  getDuplicateClassName?: (id: string) => string;
  moduleHierarchyId?: string;
  index?: number;
  isDraft?: boolean;
  isSearch?: boolean;
  isHighlighted?: boolean;
  isHighlightedExtra?: boolean;
  onNumItemsLoaded?: () => void;
  renderActions?: (content: AllessehContent) => void;
}

export const PageContentCard = ({
  content,
  onExcludeFromQuery,
  disableAltSumm,
  onRemove,
  dragHandleProps,
  moduleHierarchyId,
  index,
  getDuplicateClassName,
  isDraft,
  isSearch,
  isHighlighted,
  isHighlightedExtra,
  onNumItemsLoaded,
  renderActions
}: PageContentCardProps) => {
  const { currentProperty } = useAuth();
  const { data: contentConstants } = useContentConstants();
  const { staleIds } = useIssue();
  const displayPublishedUrl =
    content.data.attributes.content_status === 'live' &&
    (content.data.type === 'article' || content.data.type === 'SummaryModel');
  const { isModalOpen, setIsModalOpen, publicationSettings } = useAltSummModal();

  const { setAllContentIds, hasRecentlyPublished, page } = usePagePublish();
  const { setSelectedAltSummVariant } = useSummarianContext();

  useEffect(() => {
    if (page?.config?.summaryPriority) {
      setSelectedAltSummVariant(page.config.summaryPriority as AltSummVariant);
    }
  }, [page?.config?.summaryPriority, setSelectedAltSummVariant]);

  const handleViewPublishedUrl = () => {
    window.open(content.data.attributes.source_url, '_blank');
  };

  useEffect(() => {
    if (onNumItemsLoaded) onNumItemsLoaded();
  }, [onNumItemsLoaded]);

  const isStale = staleIds.includes(content.data.id);

  useEffect(() => {
    if (moduleHierarchyId && index !== undefined && isDraft) {
      setAllContentIds((prevState) => addModuleItem(prevState, { index, moduleHierarchyId }, [content.data.id]));
    }
  }, [content.data.id, moduleHierarchyId, index, setAllContentIds, isDraft, hasRecentlyPublished, page?.pageModules]);

  const liveCoverageUrl = useMemo(
    () => buildLiveCoverageUrlSettings(content.data.attributes.source_url, content.data.attributes.product),
    [content.data.attributes.source_url, content.data.attributes.product]
  );

  const handleViewLiveCoverageUrl = () => {
    if (!liveCoverageUrl) return;
    window.open(liveCoverageUrl, '_blank');
  };

  const handleEditAltSumm = () => {
    const isLiveCoverage = content.data.attributes.product.includes('Live Coverage');
    if (isLiveCoverage) {
      handleViewLiveCoverageUrl();
    } else {
      setIsModalOpen(!isModalOpen);
    }
  };

  return (
    <Box
      data-testid="page-content-card-container"
      className={classnames(
        styles.card,
        isStale && styles.stale,
        isHighlighted ? styles.highlight : isHighlightedExtra && styles.highlightExtra,
        getDuplicateClassName?.(content.data.id)
      )}
      padding={{ all: 'sm' }}
      data-allesseh-id={content.data.id}
      {...dragHandleProps}
    >
      <ContentCard content={content} isIconPresent isSearch={isSearch} />

      <Dropdown
        data-testid="page-content-card-elipsis-dropdown"
        padding={{ all: 'none' }}
        position="right"
        trigger={<IconThreeDotsVer data-testid="page-content-card-elipsis-icon" color="asphalt" />}
      >
        {renderActions && (
          <>
            <Wrapper data-testid="page-content-card-allesseh-content" padding={{ all: 'md' }}>
              {renderActions(content)}
            </Wrapper>
            <Divider />
          </>
        )}
        {onExcludeFromQuery && (
          <>
            <Wrapper padding={{ all: 'md' }}>
              <Button
                data-testid="page-content-card-exclude-query-button"
                tertiary
                margin={{ top: 'sm' }}
                icon={IconTrash as SvgComponent}
                color="lava"
                onClick={onExcludeFromQuery}
              >
                Exclude from Query
              </Button>
            </Wrapper>
            <Divider />
          </>
        )}
        {onRemove && (
          <>
            <Wrapper padding={{ all: 'md' }}>
              <Button
                data-testid="page-content-card-remove-button"
                tertiary
                margin={{ top: 'sm' }}
                icon={IconMinusCircle as SvgComponent}
                color="lava"
                onClick={onRemove}
              >
                Remove from module
              </Button>
            </Wrapper>
            <Divider />
          </>
        )}
        <Group data-testid="page-content-card-options" margin={{ all: 'md' }} gap="xs">
          <EditAltSummOption
            shouldDisplay={(isDraft || isSearch) && content.data.attributes.type !== 'commerceproduct'}
            disableAltSummEdit={
              disableAltSumm ?? isAltSummDisabled(content, currentProperty ?? '', publicationSettings, contentConstants)
            }
            handleEditAltSumm={handleEditAltSumm}
          />
          <OpenInNewsPress content={content} />
          <NewsgridButton content={content} />
          {displayPublishedUrl && (
            <>
              <Button
                data-testid="page-content-card-published-url-button"
                tertiary
                icon={IconExternalLink as SvgComponent}
                iconPosition="right"
                onClick={handleViewPublishedUrl}
              >
                View Published URL
              </Button>

              <CopyPublishedURL content={content} />
            </>
          )}
          <ExternalLink
            contentId={content.data.id}
            isPublished={!!content.data.attributes.published_datetime}
            type={ExternalLinkContentType.ARTICLE}
            embargo={content.data.attributes.content_status === 'embargo'}
          />
        </Group>
      </Dropdown>
      {(isDraft || isSearch) && isModalOpen && window.isSummarianReady && (
        <AltSummModal content={content} setIsModalOpenControlled={setIsModalOpen} isModalOpenControlled={isModalOpen} />
      )}
    </Box>
  );
};
