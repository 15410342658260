import { createContext, useCallback, useEffect, useReducer, useRef, useState } from 'react';

import { ResponsiveLoader } from 'components/responsive-loader/ResponsiveLoader';
import { DEFAULT_CHART_OBJECT, DEFAULT_VIDEO_OBJECT } from 'hooks/altsumm/useControlledQuery';
import { getDomain } from 'hooks/altsumm/useMediaForm';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { useAuthToken } from 'hooks/useAuthToken';
import {
  getSnippetyData,
  SnippetyImageData,
  SnippetyResponseWithChart,
  SnippetyResponseWithVideo,
  VideoDataForSummarian
} from 'hooks/useSnippetyQuery';
import { createChartUrl } from 'utils/chart';
import { asyncNoop as noop } from 'utils/noop';
import { decodeHtmlEntities, encodeHtmlEntities } from 'utils/text';
import {
  createVideoUrl,
  getDefaultBody,
  getDefaultBullets,
  getDefaultHeadline,
  getDefaultIframeImage,
  getDefaultImage,
  getDefaultSubhead
} from './SummarianContext.utils';
import { AltSummFields, AltSummVariant, summarianReducer } from './summarianReducer';

export type { AltSummVariant };

export const DEFAULT_ALT_SUM_ID = 'U.S. Home';

export interface SummarianContext {
  selectedAltSummVariant: AltSummVariant;
  setSelectedAltSummVariant: (selectedAltSummVariant: AltSummVariant) => void;
  trackContent: (content: AllessehContent) => void;
  getAltSummFields: (
    content: AllessehContent,
    altSummVariant?: AltSummVariant
  ) => {
    headline: string;
    body: string;
    image: string;
    video: string;
    videoImage?: string;
    videoUrl?: string;
    bullets: string[];
    subhead: string;
    chart: string;
    chartlosId: string;
    proxy: SummarianProxy | undefined;
  };
  setAltSummFields: (contentId: AllessehContent, fields: Omit<AltSummFields, 'proxy'>) => void;
  eventTarget: EventTarget;
}

export interface SummarianChangeEvent {
  data: unknown;
  path: string[];
  op: {
    ld?: string;
    p?: any[];
    si?: string;
    oi?: string[];
    sd?: string;
  };
}

const supportedProps = ['headline', 'body', 'images', 'bullets', 'subhead', 'chartlosId', 'videos'] as const;
type SupportedProps = typeof supportedProps[number];
const supportedPropsSet = new Set<string>(supportedProps);

const DEFAULT_SUMMARIAN_STATE: SummarianContext = {
  selectedAltSummVariant: 'U.S. Home',
  setSelectedAltSummVariant: noop,
  trackContent: noop,
  // @ts-expect-error - This is a dummy function, no need to match the signature
  getAltSummFields: noop,
  eventTarget: new EventTarget()
};

interface SummarianProviderProps {
  children: React.ReactNode;
}

export const SummarianContext = createContext(DEFAULT_SUMMARIAN_STATE);

export const SummarianProvider = ({ children }: SummarianProviderProps) => {
  const authToken = useAuthToken();
  const [isSummarianSettled, setIsSummarianSettled] = useState(window.hasSummarianSettled);
  const { data: publicationSettingsResp } = usePublicationSettings();
  const priorityAltSum =
    (publicationSettingsResp?.publicationSetting.defaultAltSumm as AltSummVariant | undefined) ?? DEFAULT_ALT_SUM_ID;
  const [state, dispatch] = useReducer(summarianReducer, {
    currentAltSummVariant: priorityAltSum,
    altSummCache: {
      'U.S. Home': {},
      'Home-Page': {},
      ITP: {},
      NewsPlus: {},
      APIImage: {},
      Mobile: {},
      SEO: {},
      'Social Open Graph': {}
    },
    trackedItems: new Map<string, AllessehContent>()
  });
  const eventTarget = useRef(new EventTarget());

  useEffect(() => {
    const onSummarianSettled = () => {
      setIsSummarianSettled(true);
    };

    window.summarianSettledEventTarget.addEventListener('summarianSettled', onSummarianSettled);

    return () => {
      window.summarianSettledEventTarget.removeEventListener('summarianSettled', onSummarianSettled);
    };
  }, []);

  useEffect(() => {
    if (!window.isSummarianReady || state.trackedItems.size === 0) return;

    const trackNewItems = async () => {
      const promises = [...state.trackedItems.entries()].map(async ([contentId, content]) => {
        const hasAddedContentAlready = !!state.altSummCache[state.currentAltSummVariant]?.[contentId];
        if (hasAddedContentAlready) return;

        const { currentAltSummVariant } = state;

        // Use cached proxy if available
        let altSumProxy = state.altSummCache[currentAltSummVariant]?.[contentId]?.proxy;
        if (!altSumProxy) {
          altSumProxy = await window.summarianShareDbAPI.proxy(contentId, currentAltSummVariant);
        }

        const headline = (await altSumProxy.headline) ?? getDefaultHeadline(content, priorityAltSum);
        const body = (await altSumProxy.body) ?? getDefaultBody(content, priorityAltSum);
        const bulletsFromProxy = await altSumProxy.bullets;
        let bullets: string[] = [];
        if (bulletsFromProxy) {
          bullets = Array.from(bulletsFromProxy);
        }
        const subhead = (await altSumProxy.subhead) ?? '';
        const imagesFromProxy = await altSumProxy.images;
        let image = imagesFromProxy?.[0]?.href;
        const videosFromProxy = await altSumProxy.videos;
        const video = videosFromProxy?.[0]?.guid;
        const videoImage = videosFromProxy?.[0]?.sizes?.full.url;
        const chartlosId = await altSumProxy.chartlosId;
        let chart = '';
        if (chartlosId) {
          const chartData = (await getSnippetyData(
            authToken,
            getDomain(publicationSettingsResp?.publicationSetting, 'chart', chartlosId)
          )) as SnippetyResponseWithChart;
          chart = createChartUrl(chartData);
        }

        if (!video && !chartlosId && getDefaultImage(content, priorityAltSum)) {
          image = getDefaultImage(content, priorityAltSum);
        }

        // eslint-disable-next-line no-underscore-dangle
        altSumProxy.__proxy__.on('change', async ({ path, data, op }: SummarianChangeEvent) => {
          if (!window.isSummarianReady) return;

          const prop = path[0];
          if (supportedPropsSet.has(prop)) {
            const supportedProp = prop as SupportedProps;

            if (supportedProp === 'images') {
              const imageData = data as SnippetyImageData | SnippetyImageData[] | undefined;
              const imageHref = Array.isArray(imageData) ? imageData[0]?.href : imageData?.href;
              dispatch({
                type: 'UPDATE_CONTENT_ITEM',
                payload: {
                  id: contentId,
                  fields: { image: imageHref ?? getDefaultImage(content, priorityAltSum) }
                }
              });
            } else if (supportedProp === 'videos') {
              const videoData = data as VideoDataForSummarian | [VideoDataForSummarian] | undefined | {};
              const videoDataItem = Array.isArray(videoData)
                ? (videoData[0] as VideoDataForSummarian)
                : videoData && typeof videoData === 'object' && 'guid' in videoData
                ? videoData
                : undefined;
              if (videoDataItem?.guid && videoDataItem.guid.length > 0) {
                const { guid } = videoDataItem;
                const domain = getDomain(publicationSettingsResp?.publicationSetting, 'video', guid);
                const videoData = (await getSnippetyData(authToken, domain)) as SnippetyResponseWithVideo;
                dispatch({
                  type: 'UPDATE_CONTENT_ITEM',
                  payload: {
                    id: contentId,
                    fields: {
                      videoImage: videoData.media,
                      video: videoData.url,
                      videoUrl: videoData.url
                    }
                  }
                });
              } else {
                dispatch({
                  type: 'UPDATE_CONTENT_ITEM',
                  payload: {
                    id: contentId,
                    fields: DEFAULT_VIDEO_OBJECT
                  }
                });
              }
            } else if (supportedProp === 'chartlosId') {
              const chartId = data as string;
              if (chartId.length > 0) {
                const newChartId = chartId.includes('cdc_') ? chartId : `cdc_${chartId}`;
                const chartData = (await getSnippetyData(
                  authToken,
                  getDomain(publicationSettingsResp?.publicationSetting, 'chart', newChartId)
                )) as SnippetyResponseWithChart;
                dispatch({
                  type: 'UPDATE_CONTENT_ITEM',
                  payload: {
                    id: contentId,
                    fields: {
                      chart: createChartUrl(chartData),
                      chartlosId: `cdc_${chartData.tools.chart.id}`
                    }
                  }
                });
              } else {
                dispatch({
                  type: 'UPDATE_CONTENT_ITEM',
                  payload: {
                    id: contentId,
                    fields: DEFAULT_CHART_OBJECT
                  }
                });
              }
            } else if (supportedProp === 'bullets') {
              let updatedBullets = [...bullets];
              if (op.oi) {
                updatedBullets = data as string[];
                dispatch({
                  type: 'UPDATE_CONTENT_ITEM',
                  payload: { id: contentId, fields: { bullets: [...updatedBullets] } }
                });
              } else if (altSumProxy?.bullets) {
                const altSum = await altSumProxy.bullets;
                updatedBullets = Array.isArray(altSum) ? [...altSum] : [];
              }
              dispatch({
                type: 'UPDATE_CONTENT_ITEM',
                payload: { id: contentId, fields: { bullets: [...updatedBullets] } }
              });
            } else {
              dispatch({
                type: 'UPDATE_CONTENT_ITEM',
                payload: { id: contentId, fields: { [supportedProp]: encodeHtmlEntities(data as string) } }
              });
            }

            eventTarget.current.dispatchEvent(new CustomEvent('itemUpdated', { detail: contentId }));
          }
        });

        dispatch({
          type: 'UPDATE_CONTENT_ITEM',
          payload: {
            id: contentId,
            proxy: altSumProxy,
            fields: {
              headline,
              body: body || getDefaultBody(content, priorityAltSum),
              image,
              video: video && createVideoUrl(video, publicationSettingsResp?.publicationSetting),
              videoImage,
              videoUrl: video && createVideoUrl(video, publicationSettingsResp?.publicationSetting),
              bullets,
              chartlosId,
              subhead,
              chart,
              iframe: getDefaultIframeImage(content, priorityAltSum)
            }
          }
        });
      });

      await Promise.all(promises);
    };

    void trackNewItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.trackedItems, state.currentAltSummVariant]);

  const setSelectedAltSummVariant = useCallback((selectedAltSummVariant: AltSummVariant) => {
    dispatch({ type: 'SET_CURRENT_ALT_SUMM_VARIANT', payload: selectedAltSummVariant });
  }, []);

  useEffect(() => {
    setSelectedAltSummVariant(priorityAltSum);
  }, [priorityAltSum, setSelectedAltSummVariant]);

  const trackContent = useCallback(
    (content: AllessehContent) => {
      const { altSummCache, currentAltSummVariant, trackedItems } = state;
      if (altSummCache[currentAltSummVariant]?.[content.data.id] || trackedItems.has(content.data.id)) return;

      dispatch({ type: 'TRACK_CONTENT_ITEM', payload: content });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.altSummCache, state.currentAltSummVariant, state.trackedItems]
  );

  const getAltSummFields = (content: AllessehContent, altSummVariant?: AltSummVariant) => {
    const selectedAltSummVariant = altSummVariant ?? state.currentAltSummVariant;
    const itemCache = state.altSummCache[selectedAltSummVariant]?.[content.data.id];

    let image = itemCache?.image ?? '';
    let video = itemCache?.video ?? '';
    let videoImage = itemCache?.videoImage ?? '';
    let chart = itemCache?.chart ?? '';
    let chartlosId = itemCache?.chartlosId ?? '';

    if (!video && !chartlosId && getDefaultImage(content, priorityAltSum)) {
      image = getDefaultImage(content, priorityAltSum);
    } else if (video) {
      image = '';
      chart = '';
      chartlosId = '';
    } else if (chart) {
      image = '';
      video = '';
      videoImage = '';
    }

    return {
      headline: decodeHtmlEntities(itemCache?.headline ?? getDefaultHeadline(content, priorityAltSum)),
      body: decodeHtmlEntities(itemCache?.body ?? getDefaultBody(content, priorityAltSum)),
      image,
      video,
      videoImage,
      chart,
      chartlosId,
      bullets: (itemCache?.bullets ?? getDefaultBullets(content, priorityAltSum)).map(decodeHtmlEntities),
      subhead: itemCache?.subhead ?? getDefaultSubhead(content, priorityAltSum),
      iframe: itemCache?.iframe ?? getDefaultIframeImage(content, priorityAltSum),
      proxy: itemCache?.proxy
    };
  };

  const setAltSummFields = (content: AllessehContent, fields: Omit<AltSummFields, 'proxy'>) => {
    dispatch({
      type: 'UPDATE_CONTENT_ITEM',
      payload: {
        id: content.data.id,
        fields: {
          /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
          headline: encodeHtmlEntities(fields.headline),
          body: encodeHtmlEntities(fields.body || getDefaultBody(content, priorityAltSum)),
          image: fields.image || getDefaultImage(content, priorityAltSum),
          video: fields.video,
          bullets: fields.bullets || getDefaultBullets(content, priorityAltSum),
          subhead: fields.subhead || getDefaultSubhead(content, priorityAltSum),
          videoImage: fields.videoImage,
          chart: fields.chart ?? '',
          chartlosId: fields.chartlosId,
          iframe: fields.iframe || getDefaultIframeImage(content, priorityAltSum)
          /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */
        }
      }
    });
  };

  if (!isSummarianSettled) {
    // Show loader while Summarian is still loading
    return <ResponsiveLoader />;
  }

  const value = {
    selectedAltSummVariant: state.currentAltSummVariant,
    setSelectedAltSummVariant,
    trackContent,
    getAltSummFields,
    setAltSummFields,
    eventTarget: eventTarget.current
  };
  return <SummarianContext.Provider value={value}>{children}</SummarianContext.Provider>;
};
