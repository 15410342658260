import { useAuth } from '@screentone/addon-auth-wrapper';

export const useAuthToken = () => {
  const { authState } = useAuth();

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!authState?.accessToken) {
    return '';
  }

  return `Bearer ${authState.accessToken.accessToken}`;
};
