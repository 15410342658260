import { useState } from 'react';
import { Box, Button, FormLabel, Group, Input, Overlay, Select, useModalPortal } from '@screentone/core';

export type TOffPlatformInput = {
  consumer: string;
  minItems: number;
  maxItems: number;
  defaultAltSummVariant: string;
  hostingPageURL: string;
  moduleDisplayName: string;
};

interface CollectionOffPlatformOverlayProps {
  onDismiss: () => void;
  onConvert: (e: TOffPlatformInput) => void;
  currentProperty: string | null;
}

export const CollectionOffPlatformOverlay = ({
  onDismiss,
  onConvert,
  currentProperty
}: CollectionOffPlatformOverlayProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const { renderNode } = useModalPortal();
  const [offPlatformInput, setOffPlatformInput] = useState({
    consumer: currentProperty ?? '',
    minItems: 1,
    maxItems: 1,
    defaultAltSummVariant: 'U.S. Home',
    hostingPageURL: '',
    moduleDisplayName: ''
  });
  const [errors, setErrors] = useState({
    consumer: false,
    minItems: false,
    maxItems: false,
    defaultAltSummVariant: false,
    hostingPageURL: false,
    moduleDisplayName: false
  });

  const setInputError = (key: string, value: boolean) => {
    setErrors({
      ...errors,
      [key]: value
    });
  };

  const updateOffPlatformInput = (key: string, value: string | number) => {
    const castedValue = key === 'minItems' || key === 'maxItems' ? Number.parseInt(value.toString(), 10) : value;

    setOffPlatformInput({
      ...offPlatformInput,
      [key]: castedValue
    });

    if (!castedValue) {
      setInputError(key, true);
    } else {
      setInputError(key, false);
    }
  };

  const handleConvert = () => {
    onConvert(offPlatformInput);
    onDismiss();
  };

  const hasErrors = Object.values(errors).some((error) => error);
  const someEmpty = Object.values(offPlatformInput).some((input) => !input);
  const defaultAltSummVariants = ['U.S. Home', 'NewsPlus', 'APIImage', 'Mobile', 'SEO', 'Social Open Graph'];

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <Overlay status="open" renderNode={renderNode}>
      <Box data-testid="collection-off-platform-modal-container">
        <Box.Title>Convert to Off Platform</Box.Title>
        <Box.Content>
          <Group direction="column" gap="md">
            <FormLabel label="Module Display Name" labelPosition="top" required>
              <Input
                data-testid="module-display-name-input"
                value={offPlatformInput.moduleDisplayName}
                error={errors.moduleDisplayName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateOffPlatformInput('moduleDisplayName', e.target.value)
                }
              />
            </FormLabel>
            <FormLabel label="Consumer" labelPosition="top" required>
              <Input
                data-testid="module-consumer-input"
                value={offPlatformInput.consumer}
                error={errors.consumer}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateOffPlatformInput('consumer', e.target.value)
                }
              />
            </FormLabel>
            <FormLabel label="Hosting Page URL" labelPosition="top" required>
              <Input
                data-testid="module-hosting-page-url-input"
                value={offPlatformInput.hostingPageURL}
                error={errors.hostingPageURL}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateOffPlatformInput('hostingPageURL', e.target.value)
                }
              />
            </FormLabel>
            <FormLabel label="Default Alt-Summ Variant" labelPosition="top" required>
              <Select
                data-testid="module-default-alt-summ-variant-select"
                name="defaultAltSummVariant"
                value={offPlatformInput.defaultAltSummVariant}
                error={errors.defaultAltSummVariant}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateOffPlatformInput('defaultAltSummVariant', e.target.value)
                }
              >
                {defaultAltSummVariants.map((altSumm) => (
                  <option value={altSumm} key={altSumm}>
                    {altSumm}
                  </option>
                ))}
              </Select>
            </FormLabel>
            <FormLabel label="Min. Items" labelPosition="top" required>
              <Input
                data-testid="module-min-items-input"
                type="number"
                value={offPlatformInput.minItems}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateOffPlatformInput('minItems', e.target.value)
                }
                error={errors.minItems}
                min={1}
              />
            </FormLabel>
            <FormLabel label="Max. Items" labelPosition="top" required>
              <Input
                data-testid="module-max-items-input"
                type="number"
                value={offPlatformInput.maxItems}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateOffPlatformInput('maxItems', e.target.value)
                }
                error={errors.maxItems}
                min={offPlatformInput.minItems}
              />
            </FormLabel>
          </Group>
          <Group gap="sm" align="stretch" margin={{ top: 'xl' }}>
            <Group.Item flex>
              <Button secondary fullWidth onClick={onDismiss}>
                Cancel
              </Button>
            </Group.Item>
            <Group.Item flex>
              <Button
                primary
                fullWidth
                onClick={handleConvert}
                disabled={hasErrors || someEmpty}
                data-testid="convert-button"
              >
                Convert
              </Button>
            </Group.Item>
          </Group>
        </Box.Content>
      </Box>
    </Overlay>
  );
};
