import { ErrorPage } from '@screentone/addon-auth-wrapper';

import { ResponsiveLoader } from 'components';
import { NarrowPageWrapper } from 'components/narrow-page-wrapper/NarrowPageWrapper';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import { useEnabledNewsletter } from 'features/newsletters/hooks/useEnabledNewsletter';
import Newsletters from 'features/newsletters/Newsletters';

const NewsletterPage = () => {
  const { publicationSetting, isLoading, enabledNewsletter } = useEnabledNewsletter();

  if (isLoading) {
    return (
      <PageRootContainer>
        <ResponsiveLoader />
      </PageRootContainer>
    );
  }

  if (!enabledNewsletter) {
    return <ErrorPage type="404" />;
  }

  return (
    <PageRootContainer>
      <NarrowPageWrapper>
        <Newsletters publicationSetting={publicationSetting} />
      </NarrowPageWrapper>
    </PageRootContainer>
  );
};

export default NewsletterPage;
