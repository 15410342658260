import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Dialog, Divider, Typography, Wrapper } from '@screentone/core';

import { WidePageWrapper } from 'components';
import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { NewsletterDto, NewsletterPageContainer } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';
import { NewsletterMultitabSection } from './components/newsletter-multitab-section/NewsletterMultitabSection';
import { TitleHeader } from './components/title-header/TitleHeader';
import { useNewsletterPublish } from './hooks/useNewsletterPublish';
import styles from './NewsletterEdit.module.scss';

export const ReadOnlyNewsletter = ({ isScheduled }: { isScheduled: boolean }) => {
  const [sendNowOpen, setSendNowOpen] = useState<boolean>(false);
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);

  const { root: data, metadata, renderEntity } = useDataModelContext<NewsletterPageContainer>();

  const { data: publicationSettingResp } = usePublicationSettings();
  const navigate = useNavigate();

  const { handleSendTest, handleSendSchedule, handleReschedule, handleDeleteSchedule } = useNewsletterPublish(
    publicationSettingResp?.publicationSetting
  );

  const handleSendTestClick = async (emails: string) => {
    await handleSendTest(emails);
  };

  const handleSendNowClick = async () => {
    await handleSendSchedule();

    setSendNowOpen(false);
    navigate(`/${metadata.publicationKey}/newsletters/${metadata.allessehId}`);
  };

  const handleRescheduleClick = async (publishUtc: number) => {
    const schedule = await handleReschedule(publishUtc);

    if (schedule) {
      navigate(`/${metadata.publicationKey}/newsletters/scheduled/${metadata.allessehId}/${schedule.publishUtc}`);
    }
  };

  const handleDeleteClick = async () => {
    await handleDeleteSchedule();

    navigate(`/${metadata.publicationKey}/newsletters`);
  };

  return (
    <WidePageWrapper>
      <TitleHeader
        readOnly
        isScheduled={isScheduled}
        newsletterName={metadata.name}
        handleScheduleClick={handleRescheduleClick}
        handleSendNowClick={() => {
          setSendNowOpen(true);
        }}
        handleSendTestClick={handleSendTestClick}
        publicationSetting={publicationSettingResp?.publicationSetting}
      />
      <Divider />
      <div className={styles.container}>
        <Wrapper className={styles.draftWrapper} margin={{ right: 'md' }} padding={{ top: 'mlg' }}>
          <Wrapper margin={{ all: 'md' }}>{renderEntity(data, { hierarchyId: '', withoutDragNDrop: true })}</Wrapper>
          <Box margin={{ all: 'md' }}>
            <Box.Title data-testid="newsletters-custom-title">Custom Content</Box.Title>
            <Box.Content data-testid="newsletters-custom-content-data">
              <Typography variant="label3">Sender Name</Typography>
              <Typography data-testid="newsletter-custom-sender-name" margin={{ bottom: 'md' }}>
                {data.attributes.senderName}
              </Typography>
              <Typography variant="label3">Reply-To Email</Typography>
              <Typography data-testid="newsletter-custom-reply-email" margin={{ bottom: 'md' }}>
                {data.attributes.replyToEmail}
              </Typography>
              <Typography variant="label3">Subject Line</Typography>
              <Typography data-testid="newsletter-custom-subject-line" margin={{ bottom: 'md' }}>
                {data.attributes.subject}
              </Typography>
              <Typography variant="label3">Pre-Header Text</Typography>
              <Typography data-testid="newsletter-custom-header-text" margin={{ bottom: 'md' }}>
                {data.attributes.preHeaderText}
              </Typography>
            </Box.Content>
          </Box>
        </Wrapper>
        <div className={styles.newsletterDivider} />
        <Wrapper className={styles.multitabWrapper} margin={{ left: 'md' }} padding={{ top: 'mlg' }}>
          <NewsletterMultitabSection
            readOnly
            isScheduled={isScheduled}
            campaign={{ root: data, metadata } as NewsletterDto}
            cancelCampaign={() => {
              setCancelModalOpen(true);
            }}
            publicationSetting={publicationSettingResp?.publicationSetting}
          />
        </Wrapper>
      </div>

      <Dialog status={sendNowOpen ? 'open' : 'closed'} onDismiss={() => setSendNowOpen(false)}>
        <Dialog.Title id="dialogTitle">Send Campaign</Dialog.Title>
        <Dialog.Content id="dialogDesc">
          <Typography variant="header4">{data.attributes.subject}</Typography>
          <Typography>Are you sure you want to send this immediately?</Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Button secondary onClick={() => setSendNowOpen(false)}>
            Cancel
          </Button>
          <Button primary onClick={handleSendNowClick}>
            Send
          </Button>
        </Dialog.Actions>
      </Dialog>

      <Dialog status={cancelModalOpen ? 'open' : 'closed'} onDismiss={() => setCancelModalOpen(false)}>
        <Dialog.Title id="dialogTitle">Cancel Campaign</Dialog.Title>
        <Dialog.Content id="dialogDesc">
          <Typography>Are you sure you want to cancel this campaign?</Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Button secondary onClick={() => setCancelModalOpen(false)}>
            Keep Campaign
          </Button>
          <Button primary onClick={handleDeleteClick} data-testid="newsletter-cancel-campaign">
            Cancel Campaign
          </Button>
        </Dialog.Actions>
      </Dialog>
    </WidePageWrapper>
  );
};
