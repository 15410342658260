import { Button, Dialog, FormHelperText, Loader } from '@screentone/core';

type ConfirmationDialogProps = {
  title: string;
  isLoading: boolean;
  description: string;
  hint?: string | JSX.Element;
  disabled?: boolean;
  onClickFunc: () => void;
  showDialog: (show: boolean) => void;
};

export const ConfirmationDialog = ({
  title,
  isLoading,
  showDialog,
  onClickFunc,
  description,
  hint,
  disabled
}: ConfirmationDialogProps) => (
  <Dialog
    status="open"
    aria-labelledby="dialogTitle"
    aria-describedby="dialogDesc"
    onDismiss={() => !isLoading && showDialog(false)}
    disabled={isLoading}
  >
    <Dialog.Title id="dialogTitle">{title}</Dialog.Title>
    <Dialog.Content id="dialogDesc" data-testid="confirmation-msg">
      <div>{description}</div>
      <br />
      {hint && <FormHelperText>{hint}</FormHelperText>}
    </Dialog.Content>
    <Dialog.Actions>
      <Button secondary onClick={() => showDialog(false)} disabled={isLoading}>
        Cancel
      </Button>
      <Button primary onClick={onClickFunc} disabled={isLoading || disabled} data-testid="confirm-button">
        {isLoading ? <Loader size="md" /> : 'Confirm'}
      </Button>
    </Dialog.Actions>
  </Dialog>
);
