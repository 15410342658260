import { Link } from 'react-router-dom';
import { HeaderBar, useAuth } from '@screentone/addon-auth-wrapper';
import { Header, useHeader } from '@screentone/core';

import { Artifact, PublicationSetting } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';

interface UseHeader {
  closeDrawer: () => void;
}

type RouteConfig = { path: string; name: string };

const mobileSidebarLabel = 'Curation Navigation';

const ARTIFACT_ROUTE_CONFIGS: Record<Artifact, RouteConfig> = {
  [Artifact.Page]: { path: '', name: 'Pages' },
  [Artifact.Collection]: { path: '/collections', name: 'Collections' },
  [Artifact.OffPlatform]: { path: '/off-platform', name: 'Off-Platform' },
  [Artifact.ItpIssue]: { path: '/issues', name: 'ITP Issues' },
  [Artifact.Newsletter]: { path: '/newsletters', name: 'Newsletters' },
  [Artifact.Banner]: { path: '/banners', name: 'Banners' }
};

const createMobileSidebarComponentProps = (
  publicationSettings: PublicationSetting | undefined
): RouteConfig[] | undefined => {
  const allArtifacts = new Set(Object.values(Artifact));

  const enabledArtifacts = publicationSettings?.enabledArtifacts.filter((artifact) => allArtifacts.has(artifact));
  const componentProps = enabledArtifacts?.map((artifact) => ARTIFACT_ROUTE_CONFIGS[artifact]);

  return componentProps;
};

const HeaderBarWrapper = ({ children }: { children: React.ReactNode }) => {
  const { currentProperty } = useAuth();
  const useHeaderHook = useHeader as () => UseHeader;
  const { closeDrawer } = useHeaderHook();
  const { data: publicationSettingsResp } = usePublicationSettings();

  const publicationSettings = publicationSettingsResp?.publicationSetting;
  const mobileSidebarComponentProps = createMobileSidebarComponentProps(publicationSettings);
  const createMobileSidebarComponent = () => (
    <Header.LinkGroup>
      {mobileSidebarComponentProps?.map(({ path, name }) => (
        <Header.Link
          key={name}
          componentEl={Link}
          to={`/${(currentProperty && currentProperty) ?? ''}${path}`}
          onClick={() => closeDrawer()}
        >
          {name}
        </Header.Link>
      ))}
    </Header.LinkGroup>
  );
  const mobileSidebar = currentProperty && {
    label: mobileSidebarLabel,
    component: createMobileSidebarComponent()
  };
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <HeaderBar mobileSidebar={mobileSidebar}>{children}</HeaderBar>
  );
};

export default HeaderBarWrapper;
