import { useParams } from 'react-router-dom';
import { ErrorPage } from '@screentone/addon-auth-wrapper';

import { ResponsiveLoader } from 'components';
import { ViewPublishedURL } from 'components/context-menu-actions/view-published-url/ViewPublishedURL';
import { PageRootContainer } from 'components/page-root-container/PageRootContainer';
import {
  ContextMenuActionsProvider,
  MenuActions,
  MenuActionsKeys
} from 'contexts/context-menu-actions/ContextMenuActionsContext';
import { DataModelProvider, DataModelType } from 'contexts/datamodel/DataModelContext';
import { SummarianProvider } from 'contexts/summarian/SummarianContext';
import { ArticleItem, BaseMetadata, NewsletterPageContainer, useNewsletterByIdQuery } from 'data/generated/graphql';
import { useEnabledNewsletter } from 'features/newsletters/hooks/useEnabledNewsletter';
import { ReadOnlyNewsletter } from 'features/newsletters/ReadOnlyNewsletter';
import { useConvertedProperty } from 'hooks';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { safelyParseContent } from 'utils/temp';

const contextActions: MenuActions = {
  [MenuActionsKeys.DraftArticle]: [
    {
      component: ({ entity }) => {
        const article = entity as ArticleItem;
        return <ViewPublishedURL content={safelyParseContent<AllessehContent>(article.content)} />;
      }
    }
  ]
};

const SavedNewsletterPage = () => {
  const { campaignId } = useParams();
  const currentProperty = useConvertedProperty();

  const { data, isLoading, isError } = useNewsletterByIdQuery({
    id: campaignId ?? '',
    publicationKey: currentProperty ?? ''
  });

  const { isLoading: isLoadingEnabledNewsletter, enabledNewsletter } = useEnabledNewsletter();

  if (!campaignId || !currentProperty) {
    return <></>;
  }

  if (isError || !enabledNewsletter) {
    return <ErrorPage type="500" />;
  }

  if (isLoading || isLoadingEnabledNewsletter) {
    return (
      <PageRootContainer>
        <ResponsiveLoader />
      </PageRootContainer>
    );
  }

  return (
    <SummarianProvider>
      <PageRootContainer>
        <DataModelProvider
          type={DataModelType.NewsletterDTO}
          root={data.newsletterById.root as NewsletterPageContainer}
          metadata={data.newsletterById.metadata as BaseMetadata}
        >
          <ContextMenuActionsProvider actions={contextActions}>
            <ReadOnlyNewsletter isScheduled={false} />
          </ContextMenuActionsProvider>
        </DataModelProvider>
      </PageRootContainer>
    </SummarianProvider>
  );
};

export default SavedNewsletterPage;
