import { useEffect, useState } from 'react';

import { Artifact } from 'data/generated/graphql';
import { usePublicationSettings } from 'hooks/publication-settings';

export const useEnabledNewsletter = () => {
  const [enabledNewsletter, setEnabledNewsletter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { data, isLoading: isLoadingPublicationSettings } = usePublicationSettings();

  useEffect(() => {
    if (!isLoadingPublicationSettings) {
      setEnabledNewsletter(data?.publicationSetting.enabledArtifacts.includes(Artifact.Newsletter) ?? false);
      setIsLoading(false);
    }
  }, [data, isLoadingPublicationSettings]);

  return {
    isLoading: isLoading || isLoadingPublicationSettings,
    enabledNewsletter,
    publicationSetting: data?.publicationSetting
  };
};
