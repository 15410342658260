import { useEffect, useMemo } from 'react';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Button, IconEdit, Tooltip, Wrapper } from '@screentone/core';

import { AltSummModal } from 'components/altsumm-modal/AltSummModal';
import { useContextMenuActions } from 'contexts/context-menu-actions/useContextMenuActions';
import { usePublicationSettings } from 'hooks/publication-settings';
import { AllessehContent } from 'hooks/useAllessehContentQuery';
import { useContentConstants } from 'hooks/useContentConstants';
import { buildLiveCoverageUrlSettings } from 'utils/cms';
import styles from './EditAltSumm.module.scss';
import { isAltSummDisabled } from './EditAltSumm.utils';

interface EditAltSummProps {
  content: AllessehContent;
  className?: string;
}

const summarianNotReadyMessage = `There was an issue loading Summarian or you do not have Summarian access.
Please contact the #curation team on Slack.`;

export const EditAltSumm = ({ content, className }: EditAltSummProps) => {
  const { currentProperty } = useAuth();
  const { data: contentConstants } = useContentConstants();
  const { data: publicationSettingsResp } = usePublicationSettings();
  const { setActionModalComponent, setIsModalOpen } = useContextMenuActions();
  const isLiveCoverage = content.data.attributes.product.includes('Live Coverage');

  const liveCoverageUrl = useMemo(
    () => buildLiveCoverageUrlSettings(content.data.attributes.source_url, content.data.attributes.product),
    [content.data.attributes.source_url, content.data.attributes.product]
  );

  const handleViewLiveCoverageUrl = () => {
    if (!liveCoverageUrl) return;
    window.open(liveCoverageUrl, '_blank');
  };

  const handleEditAltSumm = () => {
    if (isLiveCoverage) {
      handleViewLiveCoverageUrl();
    } else {
      setIsModalOpen(true);
      setActionModalComponent(<AltSummModal content={content} />);
    }
  };

  useEffect(() => {
    if (window.isSummarianReady) {
      setActionModalComponent(<AltSummModal content={content} />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActionModalComponent]);

  const publicationSettings = publicationSettingsResp?.publicationSetting;
  const shouldDisplay = content.data.attributes.type !== 'commerceproduct';
  const disableAltSummEdit = isAltSummDisabled(content, currentProperty ?? '', publicationSettings, contentConstants);
  if (!shouldDisplay || disableAltSummEdit) {
    return null;
  }

  const EditAltSummButton = (
    <Wrapper className={className}>
      <Button
        data-testid="edit-alt-summ-option-button"
        disabled={!window.isSummarianReady}
        tertiary
        icon={IconEdit as SvgComponent}
        onClick={handleEditAltSumm}
      >
        Edit Alt-Summ
      </Button>
    </Wrapper>
  );

  return (
    <>
      {window.isSummarianReady ? (
        EditAltSummButton
      ) : (
        <Tooltip data-testid="edit-alt-summ-option-tooltip">
          <Tooltip.Content
            data-testid="edit-alt-summ-option-tooltip-content"
            id="altSumModalTooltip"
            position="bottom"
            className={styles.altSumTooltip}
            size="lg"
          >
            {summarianNotReadyMessage}
          </Tooltip.Content>
          <Tooltip.Trigger data-testid="edit-alt-summ-option-tooltip-trigger" aria-describedby="altSumModalTooltip">
            {EditAltSummButton}
          </Tooltip.Trigger>
        </Tooltip>
      )}
    </>
  );
};
