import { ComponentProps } from 'react';
import { FormLabel, Switch } from '@screentone/core';

import { useDataModelContext } from 'contexts/datamodel/useDataModel';
import { MobileArticleItem } from 'data/generated/graphql';

type MobileArticleAttributeKey = 'showFlashline' | 'showImage' | 'showImageBelow';

interface MobileToggleProps extends ComponentProps<typeof Switch> {
  label: string;
  article: MobileArticleItem;
  attributeKey: MobileArticleAttributeKey;
  onCheckChange?: (checked: boolean) => void;
  hierarchyId: string | undefined;
}

const MobileToggle = ({ article, label, attributeKey, onCheckChange, hierarchyId, ...props }: MobileToggleProps) => {
  const { modifyEntity } = useDataModelContext();

  const checked = article.attributes[attributeKey];

  const handleCheckChange = () => {
    if (typeof hierarchyId === 'string') {
      modifyEntity(hierarchyId, (entity) => {
        // eslint-disable-next-line no-param-reassign
        (entity.attributes as MobileArticleItem['attributes'])[attributeKey] = !checked;
      });
    }

    onCheckChange?.(!checked);
  };

  return (
    <FormLabel label={label} labelPosition="right">
      <Switch {...props} checked={checked} onChange={handleCheckChange} />
    </FormLabel>
  );
};

export default MobileToggle;
